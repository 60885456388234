<template>
    <b-card title="Base Warranty">
        <b-col cols="12">
            <b-table
                :fields="fields"
                :items="baseWarranty"
                show-empty
            >
                <template #cell(actions)="row">
                    <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                        <template #button-content>
                            <feather-icon size="1x" icon="MenuIcon" />
                        </template>
                        <b-dropdown-item style="text-align:center" v-if="permission.edit">
                            <b-button variant="outline-primary" v-if="row.item.typeOfWarranty==='Base Warranty'"  @click="toEditWarranty(row.item._id)">
                                <feather-icon icon="EditIcon"/>
                            </b-button>
                            <b-button variant="outline-primary" v-if="row.item.typeOfWarranty==='Remarks'"  @click="toEditFlag(row.item._id)">
                                <feather-icon icon="EditIcon"/>
                            </b-button>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
        </b-col>
    </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import {userAccess} from '@/utils/utils'

export default {
    data() {
        return {
            fields: [
                { key: 'name', text: 'Name' },
                { key: 'month', text: 'Month' },
                { key: 'actions', text: 'Action' }
            ],
            baseWarranty: [],
        }
    },
    mounted() {
        this.getBaseWarrantyAll().then((data) => {
            console.log(data)
            var temp = data.filter((x)=>{return x.typeOfWarranty!="Option"})
            this.baseWarranty = temp;
        })
    },
    methods: {
        ...mapActions({
            getBaseWarranty: 'warranty/getBaseWarranty',
            getBaseWarrantyAll: 'warranty/getBaseWarrantyAll'
        }),
        toEditWarranty(id) {
            this.$router.push(`/warranty/base-warranty/edit/${id}`)
        },
        toEditFlag(id) {
            this.$router.push(`/warranty/flag-expiry/edit/${id}`)
        }
    },
    computed: {
        permission() {
      return userAccess("Base Warranty", "warranty_master_menu");
    },
    },
    created() {
    document.title = 'Base Warranty | RSP'
  },
}
</script>